import {
    Container,
    Dropdown,
    Grid, Icon,
    Label,
    List,
    Menu,
    Placeholder, Popup,
    Segment
} from 'semantic-ui-react'
import {useDispatch, useSelector} from "react-redux";
import {fetchIntervention, fetchInterventionHistoric} from "../../reducers/FollowUp/followup.actions";
import React, {useEffect, useState} from "react";
import NumberFormat from 'react-number-format';
import axiosInstance, {axiosInstanceTOTO} from "../../utils/axiosInstance";
import {getCategoryLabel, getRepairLabel} from "../../utils/repairLabels"
import {isIE} from 'react-device-detect';
import {DELETED_INTER} from "../../reducers/FollowUp/followup.types";
import {useHistory} from 'react-router-dom';
import {isAdmin, isSupport} from "../../utils/authorities-utils";
import DismissPopup from "../DismissPopup/DismissPopup";
import ClientDetails from "./ClientDetails";
import ProductDetails from "./ProductDetails";
import statusMap from "../../utils/status/interventionStatus"
import HistoricMenu from "../Historics/HistoricMenu";
import DiscountPopup from "../PromotionPopup/DiscountPopup";
import shipmentStatusMap from "../../utils/status/shipmentStatus";
import {
    hasDevisStepInHistoric, hasFinishedHistoric, hasNewQuoteStepInHistoric,
    hasQuoteValidatedInHistoric,
    hasRepairedNotRepariredStepInHistoric, isCanInterventionBeAbandoned, isCanInterventionBeCanceled
} from "../../utils/intervention-utils";
import {copyToClipboard} from "../../utils/ui-utils";
import {SHOW_ABANDON_POPUP} from "../../reducers/GlobalUi/globalUi.types";
import AbandonPopup from "../MenuSideBar/AbandonPopup";
import {toast} from "react-toastify";

function InterventionDetails({interventionId,supportMode,setSearch}) {

    const followup = useSelector(state => state.followup);
    const shop = useSelector(state => state.shop);
    const intervention = useSelector(state => state.followup.selectedIntervention);
    const historic = useSelector(state => state.followup.historic);


    const dispatch = useDispatch();
    const connectedUser = useSelector(state => state.user.user);
    useEffect(() => {
        if(!followup.isInterventionLoading && interventionId !== undefined) {
            dispatch(fetchIntervention(interventionId));
            dispatch(fetchInterventionHistoric(interventionId));
        }
    }, [interventionId])

    const repairList = [];
    let smaKo = false;
    if(followup.selectedIntervention.items) {
        for (const item of followup.selectedIntervention.items) {
            if(!item.status) {
                if(item.category === 'SMA_KO') {
                    smaKo = true;
                }
                repairList.push({
                    name: item.name,
                    category: item.category,
                    priceTtc: item.priceTtc
                })
            }
        }
    }

    const openAbandonPopup = () => {
        if(intervention.status === "TRANSIT_ORANGE_SAVE" || intervention.status === "TRANSIT_SAVE_ORANGE" ) {
            toast.error('L\'intervention ne peut être annulée pour un dossier en transit, attendez la réception par la boutique pour annuler l\'intervention', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            dispatch({type: SHOW_ABANDON_POPUP})
        }
    }

    const productReplaced = () => {
            axiosInstance.post("/intervention/historic",
                {
                    interventionId: followup.selectedIntervention.id,
                    status: "PRODUCT_REPLACED"
                })
                .then((response) => {
                    dispatch(fetchInterventionHistoric(interventionId));
                    toast.success("Le produit a été remplacé");
                });
    }

    const reportAnAnomaly = () => {
        axiosInstance.post("/intervention/historic",
            {
                interventionId: followup.selectedIntervention.id,
                status: "PRODUCT_ANOMALY"
            })
            .then((response) => {
                dispatch(fetchInterventionHistoric(interventionId));
                toast.success("Une anomalie a été déclaré sur le produit");
            });
    }

    const markAsAbandoned = () => {
        axiosInstance.post("/intervention/historic",
            {
                interventionId: followup.selectedIntervention.id,
                status: "PRODUCT_ABANDONED"
            })
            .then((response) => {
                dispatch(fetchInterventionHistoric(interventionId));
                toast.success("Le client ne souhaite pas venir récupérer son téléphone");
            });
    }

    const generateExtranetUrl = ()=>{
        axiosInstance.get("/extranet/token?email="+followup.selectedIntervention.customer.email+"&interventionCode="+"ORG_"+followup.selectedIntervention.id).then((response)=>{
            window.open(shop.extranetUrl+response.data,  "_blank");
        });
    }

    const goToTrepidaiIntervention = () => {
        window.open(process.env.REACT_APP_TREPIDAI_BACKOFFICE_URL + "/orange/open?orangeInterventionReference=ORG_" + followup.selectedIntervention.id, "_blank");
    }

    const displayReport = () => {
        let intId = followup.selectedIntervention.id;
        axiosInstance.get('/interventionReport/' + intId, {responseType: 'blob'})
            .then(response => {
                if (isIE) {
                    var blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });
                    window.navigator.msSaveOrOpenBlob(blob, "Rapport d'intervention ORG_" + intId + ".pdf");
                } else {
                    //Create a Blob from the PDF Stream
                    const file = new Blob(
                        [response.data],
                        {type: 'application/pdf'});
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    window.open(fileURL);
                }
            })
            .catch(error => {
            });
    }

    const displayHandle = (isQuote) => {
        let intId = followup.selectedIntervention.id;
        axiosInstance.get('/handleReport/' + intId + '?isQuote=' + isQuote, {responseType: 'blob'})
            .then(response => {
                //Create a Blob from the PDF Stream
                if (isIE) {
                    var blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });
                    window.navigator.msSaveOrOpenBlob(blob, "Prise en charge ORG_" + intId + ".pdf");
                } else {

                    const file = new Blob(
                        [response.data],
                        {type: 'application/pdf'});
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    window.open(fileURL);
                }

            })
            .catch(error => {
            });
    }

    const displayNewQuote = () => {
        let intId = followup.selectedIntervention.id;
        axiosInstance.get('/newQuote/' + intId, {responseType: 'blob'})
            .then(response => {
                //Create a Blob from the PDF Stream
                if (isIE) {
                    var blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });
                    window.navigator.msSaveOrOpenBlob(blob, "Nouveau devis ORG_" + intId + ".pdf");
                } else {

                    const file = new Blob(
                        [response.data],
                        {type: 'application/pdf'});
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    window.open(fileURL);
                }

            })
            .catch(error => {
            });
    }


    const displayRestitution = () => {
        let intId = followup.selectedIntervention.id;
        axiosInstance.get('/interventionRestitution/' + intId, {responseType: 'blob'})
            .then(response => {
                //Create a Blob from the PDF Stream
                if (isIE) {
                    var blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });
                    window.navigator.msSaveOrOpenBlob(blob, "Restitution ORG_" + intId + ".pdf");
                } else {

                    const file = new Blob(
                        [response.data],
                        {type: 'application/pdf'});
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    window.open(fileURL);
                }

            })
            .catch(error => {
            });
    }

    let needQuote = repairList && repairList.filter(repair => !repair.priceTtc || repair.priceTtc ===0).length > 0;


    return (
        <>
            <Menu attached='top'>
                <Popup position='top center' size={"mini"} content='Cliquer pour copier' trigger={
                    <Menu.Item active onClick={() => copyToClipboard("ORG_"+followup.selectedIntervention.id)}>
                        <h4>Dossier ORG_{followup.selectedIntervention.id}</h4>
                    </Menu.Item>
                } />

                <Menu.Item>
                    <span>{statusMap.get(followup.selectedIntervention.status) ? statusMap.get(followup.selectedIntervention.status).label : followup.selectedIntervention.status}</span>
                </Menu.Item>
                {/*{followup.selectedIntervention.interventionSavOriginId!==null?*/}
                {/*    <Menu.Item active style={{backgroundColor:'#f2711c!important'}}    >*/}
                {/*        <Label as='a' onClick={()=>{ {*/}
                {/*            history.push("/followup?intervention=" + followup.selectedIntervention.interventionSavOriginId);*/}
                {/*        setSearch(followup.selectedIntervention.interventionSavOriginId)*/}
                {/*        }}} >SAV du dossier Dossier ORG_{followup.selectedIntervention.interventionSavOriginId}</Label>*/}
                {/*    </Menu.Item>:""}*/}

                {(supportMode && followup.selectedIntervention.shopPartner !==undefined  && followup.selectedIntervention.shopPartner.name !==undefined)?  <Menu.Item>
                        <h4>Boutique de gestion {followup.selectedIntervention.shopPartner.name}</h4>
                </Menu.Item>:""}

                {(supportMode && followup.selectedIntervention.shopPartner !==undefined && followup.selectedIntervention.shopPartner.name !==undefined)?  <Menu.Item>
                    <h4>{shipmentStatusMap.get(followup.selectedIntervention.shopPartner.pickupType).textHistoric}</h4>
                </Menu.Item>:""}

                <Menu.Menu position='right'>
                    <Dropdown item text='Actions'>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={generateExtranetUrl}>Voir l'extranet</Dropdown.Item>
                            {isAdmin(connectedUser.authorities) ?<>
                             <Dropdown.Item onClick={goToTrepidaiIntervention}>Voir le dossier Trépidai</Dropdown.Item>
                            </> : null}

                            {isAdmin(connectedUser.authorities) ? <DiscountPopup /> :null}

                            <Dropdown.Item
                                disabled={!isCanInterventionBeCanceled(intervention, isAdmin(connectedUser.authorities))}
                                onClick={openAbandonPopup}>Abandon du dossier</Dropdown.Item>

                            {isAdmin(connectedUser.authorities) ?<Dropdown.Item onClick={productReplaced}>Remplacer le produit</Dropdown.Item> :null}

                            {isAdmin(connectedUser.authorities) ?<Dropdown.Item onClick={reportAnAnomaly}>Déclarer une anomalie</Dropdown.Item> :null}

                            {<Dropdown.Item disabled={!isCanInterventionBeAbandoned(intervention, historic)} onClick={markAsAbandoned}>Déclarer un abandon de téléphone</Dropdown.Item>}

                            {/*<DismissPopup  interventionId={followup.selectedIntervention.id}/>*/}
                        </Dropdown.Menu>
                    </Dropdown>


                    {/*<PrintPriseEnCharge selectedInterventionId={followup.selectedIntervention.id}  />*/}
                    <Dropdown item text='Document'>
                        <Dropdown.Menu>
                            {hasDevisStepInHistoric(followup.historic) ?
                                <Dropdown.Item name='displayHandle' disabled={followup.selectedIntervention.anonymized} onClick={()=>{displayHandle(true)}}>Imprimer le devis</Dropdown.Item> : null}
                            {hasQuoteValidatedInHistoric(followup.historic) ?
                            <Dropdown.Item name='displayHandle' disabled={followup.selectedIntervention.anonymized} onClick={()=>{displayHandle(false)}}>Imprimer le document de prise en charge</Dropdown.Item> : null}
                            {hasNewQuoteStepInHistoric(followup.historic) ?
                                <Dropdown.Item name='displayNewHandle' disabled={followup.selectedIntervention.anonymized} onClick={()=>{displayNewQuote()}}>Imprimer le nouveau devis</Dropdown.Item> : null}
                            {hasRepairedNotRepariredStepInHistoric(followup.historic) ?
                            <Dropdown.Item name='displayReport' disabled={followup.selectedIntervention.anonymized} onClick={()=>{displayReport()}}>Imprimer le rapport d'intervention</Dropdown.Item> : null}
                            {hasFinishedHistoric(followup.historic) ?
                                <Dropdown.Item name='displayRestitution' disabled={followup.selectedIntervention.anonymized} onClick={()=>{displayRestitution()}}>Imprimer le rapport de restitution</Dropdown.Item> : null}
                        </Dropdown.Menu>
                    </Dropdown>

                </Menu.Menu>
            </Menu>

            <Container style={{marginTop: '5px', width: '100%'}}>
                <Grid>
                    <Grid.Row>


                            <Grid.Column width={8}>

                                <ProductDetails intervention={followup.selectedIntervention} />
                                <ClientDetails interventionId={followup.selectedIntervention.id} customer={followup.selectedIntervention.customer}/>

                                <Segment>
                                    <Label attached='top'>Eléments du devis</Label>

                                    {followup.isInterventionLoading ?
                                        <Placeholder>
                                            <Placeholder.Header image>
                                                <Placeholder.Line length='medium'/>
                                                <Placeholder.Line length='full'/>
                                            </Placeholder.Header>
                                            <Placeholder.Paragraph>
                                                <Placeholder.Line length='full'/>
                                                <Placeholder.Line length='medium'/>
                                            </Placeholder.Paragraph>
                                        </Placeholder>
                                        :
                                        <>

                                    <List divided relaxed>
                                    {repairList.map((repair) => (
                                        <List.Item>
                                            <List.Icon name='check' size='large' verticalAlign='middle'
                                                       color={'green'}/>
                                            <List.Content>
                                                <List.Header>{getRepairLabel(repair.name)}
                                                    {repair.category !== 'SMA_KO' ?
                                                        <Label as='a' color='teal' size={'mini'}
                                                               style={{float: 'right'}}>
                                                            <NumberFormat
                                                                value={followup.isInterventionNotRepaired ? 0 :repair.priceTtc}
                                                                displayType={'text'}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                decimalSeparator=','
                                                                suffix=' €'
                                                            />
                                                        </Label> : repairList.length === 1 ?
                                                        <Label as='a' color='teal' size={'mini'}
                                                               style={{float: 'right'}}>
                                                            Devis à venir
                                                        </Label> : ""}
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                    ))}
                                    </List>

                                    <List relaxed>
                                        <List.Item>
                                            <List.Icon name='calculator' size='large' verticalAlign='top'/>
                                            <List.Content>
                                                <List.Header>Total
                                                    {(smaKo && repairList.length === 1) || needQuote ?
                                                        <Label as='a' color='green' size={'medium'} style={{float: 'right'}}>
                                                            Devis à venir
                                                        </Label>
                                                        : followup.selectedIntervention.interventionSavOriginId===null?
                                                    <Label as='a' color='green' size={'medium'} style={{float: 'right'}}>
                                                        <NumberFormat
                                                            value={followup.isInterventionNotRepaired ? 0 :repairList.map((repair) => (repair.priceTtc)).reduce((x, y) => x + y, 0)}
                                                            displayType={'text'}
                                                            fixedDecimalScale={true}
                                                            decimalScale={2}
                                                            decimalSeparator=','
                                                            suffix=' €'
                                                        />
                                                    </Label>:
                                                        <Label as='a' color='green' size={'medium'} style={{float: 'right'}}>
                                                            0€ SAV
                                                        </Label>}
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                        </>
                                    }

                                </Segment>


                            </Grid.Column>

                        <Grid.Column width={8}>
                            <HistoricMenu supportMode={supportMode} interventionId={interventionId}/>
                        </Grid.Column>
                    </Grid.Row>


                </Grid>

            </Container>
        </>
    );

}

export default InterventionDetails
