import {Step,} from 'semantic-ui-react'
import React from "react";
import {useSelector} from "react-redux";

function VisualDiagStepper(props) {

    const selectedRepairs = useSelector(state => state.product.selectedRepairs);

    function changeStep(step){
        props.setCurrentStep(step);
    }

    const steps = [];
    let index = 1;

    // smartphone ok ?
    steps.push({
        key: index,
        active: props.step === 'SMA',
        disabled: false,
        completed: props.step !== 'SMA',
        title: <span>Etape {index}</span>,
        onClick:()=>changeStep('SMA')
    });

    // lcd ok ?
    if(!selectedRepairs.includes('LCD')) {
        index++;
        steps.push({
            key: index,
            active: props.step === 'LCD',
            disabled: props.step === 'SMA',
            completed: props.step === 'BACK_COVER' || props.step === 'DOX' ,
            title: <span>Etape {index}</span>,
            onClick:()=>changeStep('LCD')
        });

    }

    // back cover ok ?
    if(!selectedRepairs.includes('BACK_COVER')) {
        index++;
        steps.push({
            key: index,
            active: props.step === 'BACK_COVER',
            disabled: props.step === 'SMA' || props.step === 'LCD',
            completed: props.step === 'DOX',
            title: <span>Etape {index}</span>,
            onClick:()=>changeStep('BACK_COVER')
        });
    }

    index++;
    // dox ?
    steps.push({
        key: index,
        active: props.step === 'DOX',
        disabled: props.step !== 'DOX',
        completed: false,
        title: <span>Etape {index}</span>,
        onClick:()=>changeStep('DOX')
    });

    return (
        <Step.Group items={steps} fluid />
    );
}


export default VisualDiagStepper;
