
import {useDispatch, useSelector} from "react-redux";


export function RemoteDiagAfterIdentification() {
    const product = useSelector(state => state.product);

    let listDiagToTreatManually=[];
    for(let diag of product.pricingResult)
    {
        //skip visual test
        if("BACK_COVER" === diag.category)
            continue;
        if(diag.isAllSubDiagDone !==true || diag.category === 'SMA_KO' || diag.category === 'DOX')
        {
            listDiagToTreatManually.push(diag);
        }
    }

    let smaKo = listDiagToTreatManually.filter(diag => diag.category === 'SMA_KO');
    let oxidized = listDiagToTreatManually.filter(diag => diag.category === 'DOX');

    if(smaKo.length > 0 && oxidized.length > 0) {
        listDiagToTreatManually = listDiagToTreatManually.filter(diag => diag.category  !== 'DOX');
    }

    return listDiagToTreatManually;

}

