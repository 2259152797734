import React, {useState} from 'react'
import {Button, Input, Modal} from 'semantic-ui-react'
import {axiosInstance} from "../../utils/axiosInstance";
import {LOAD_INTERVENTION} from "../../reducers/Intervention/intervention.types";
import {LOAD_CUSTOMER} from "../../reducers/Customer/customer.types";
import {LOAD_PRODUCT} from "../../reducers/Product/product.types";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";


function WebQuote() {

    const history = useHistory();
    const dispatch = useDispatch();

    const [webQuoteInterventionId, setWebQuoteInterventionId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleChangeDevisWeb = (e, {searchQuery, value}) => {
        setWebQuoteInterventionId(value);
    }

    const takeWebQuote = () => {
        if(webQuoteInterventionId != '') {
            setLoading(true);
            axiosInstance.get("/web/intervention/" + webQuoteInterventionId)
                .then((response) => {
                    setLoading(false);
                    setShowModal(true);
                })
                .catch((error) => {
                    setLoading(false);
                });
        }
    }

    const validateWebQuote = () => {
        setModalLoading(true);
        axiosInstance.post("/web/intervention/" + webQuoteInterventionId)
            .then((response) => {


                let intervention = response.data;

                dispatch({type: LOAD_INTERVENTION, intervention: intervention})
                dispatch({type: LOAD_CUSTOMER, payload: intervention.customer})

                var pricingResult = [];
                for (const item of intervention.items) {
                    pricingResult.push({
                        amountTTC: item.priceTtc,
                        amountHT: item.priceHt,
                        amountB2BTTC: item.priceB2BTtc,
                        amountB2BHT: item.priceB2BHt,
                        amountB2BTTCLab: item.priceB2BTtcLab,
                        amountB2BHTLab: item.priceB2BHtLab,
                        allSubDiagOk: item.status ? true : false,
                        category: item.category,
                        categoryLabel: item.category,
                    });
                }


                var newProduct = {
                    ...intervention.product,
                    brand: intervention.product.brandName,
                    pricingResult: pricingResult,
                    services: []
                }

                dispatch({type: LOAD_PRODUCT, payload: newProduct})
                setModalLoading(false);
                setShowModal(false);
                history.push("/quotation")
            })
            .catch((error) => {
                setModalLoading(false);
                setShowModal(false);
                console.log("error");
            });
    }


    return (
        <>
            <Input fluid onChange={handleChangeDevisWeb} value={webQuoteInterventionId} disabled={loading}
                   loading={loading}
                   action={<Button onClick={takeWebQuote}>Prendre en charge</Button>} placeholder='Numéro de dossier'
                   label={{basic: true, content: 'ORG_'}}/>

            <Modal
                onClose={() => setShowModal(false)}
                open={showModal}
            >
                <Modal.Header>Devis Web</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        Etes-vous sur de vouloir prendre en charge le devis WEB ayant pour référence
                        ORG_{webQuoteInterventionId}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => setShowModal(false)} disabled={modalLoading} >
                        Annuler
                    </Button>
                    <Button primary onClick={() => validateWebQuote()} disabled={modalLoading} loading={modalLoading}>
                        Prendre en charge
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    )

}

export default WebQuote

